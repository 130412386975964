<template>
	<v-container>
		<v-skeleton-loader
			v-if="loading"
			type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions, table"
		></v-skeleton-loader>
		<template v-else>
			<v-subheader color="gray" class="layout row">
				<v-breadcrumbs :items="breads">
					<template v-slot:divider>
						<v-icon>mdi-chevron-right</v-icon>
					</template>
				</v-breadcrumbs>
			</v-subheader>
			<v-layout row class="my-5">
				<v-flex xs12 md8>
					<v-card class="my-2">
						<v-card-title class="layout row">
							<v-flex shrink class="px-3">
								<v-avatar size="100">
									<img :src="worker.image_url" :alt="worker.full_name" />
								</v-avatar>
							</v-flex>
							<v-flex class="px-5">
								<h5>{{ worker.full_name }}</h5>
								<h5>{{ worker.document }}</h5>
								<h6>{{ worker.charge }} @ {{ worker.department }}</h6>
								<v-chip outlined style="position: absolute; top: 40px; right: 20px" color="primary" dark>{{
									worker.status | trans
								}}</v-chip>
							</v-flex>
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text>
							<v-list subheader dense>
								<v-subheader>{{ "data" | trans }}</v-subheader>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "first_name" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.first_name }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "middle_name" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.middle_name || "--" }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "last_name" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.last_name || "--" }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "document" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.document || "--" }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "code" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.code || "--" }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "email" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.email || "--" }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "phone_number" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.phone_number || "--" }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "gender" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.gender | trans }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold"
											>{{ "charge" | trans }} - {{ "department" | trans }}</v-list-item-title
										>
									</v-list-item-content>
									<v-list-item-action>{{ worker.charge | trans }} - {{ worker.department | trans }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "address" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.address || "--" }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "salary" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.salary | money }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "admission_date" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.admission_date | moment("LLL") }}</v-list-item-action>
								</v-list-item>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title class="font-weight-bold">{{ "egress_date" | trans }}</v-list-item-title>
									</v-list-item-content>
									<v-list-item-action>{{ worker.egress_date | moment("LLL") }}</v-list-item-action>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-card>
					<!-- Extra -->
					<v-card class="my-3" v-if="worker.extra && worker.extra.length > 0">
						<v-subheader>
							<v-icon left>mdi-view-list</v-icon>
							{{ "extra fields" | trans }}
						</v-subheader>
						<v-list dense>
							<v-list-item v-for="(field, index) in worker.extra" :key="index">
								<v-list-item-content>
									<v-list-item-title>{{ field.key }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action>{{ field.value }}</v-list-item-action>
							</v-list-item>
						</v-list>
					</v-card>
				</v-flex>
				<v-flex xs12 md4>
					<!-- Location -->
					<v-card class="my-3 mx-2 text-center" max-width="1000px" height="450px">
						<v-subheader class="text-capitalize layout row">
							<v-icon left>mdi-map</v-icon>
							{{ "location" | trans }}
						</v-subheader>
						<div style="height: 390px; width: 100%">
							<newton-map v-if="!loading" :center="coords" :zoom="zoom" :bearing="0" :interactive="coords[0] != 0">
								<MglMarker :coordinates="coords">
									<div slot="marker" class="text-center">
										<div class="marker elevation-10"></div>
										<div class="marker-tooltip" v-if="worker.location">
											{{ worker.location.name || worker.location.address }}
										</div>
									</div>
								</MglMarker>
								<v-overlay absolute v-if="coords[0] == 0">
									<h4>{{ "Ubicacion Desconocida" | trans }}</h4>
								</v-overlay>
							</newton-map>
						</div>
					</v-card>

					<!-- Relations⁄⁄⁄⁄ -->
					<v-card class="my-3 mx-2">
						<v-list subheader dense>
							<v-subheader>{{ "relations" | trans }}</v-subheader>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title class="font-weight-bold">{{ "job place" | trans }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action>{{ worker.puesto ? worker.puesto.full_name : "--" }}</v-list-item-action>
							</v-list-item>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title class="font-weight-bold">{{ "entity" | trans }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action>{{ worker.entidad ? worker.entidad.full_name : "--" }}</v-list-item-action>
							</v-list-item>
							<!-- <v-list-item>
								<v-list-item-content>
									<v-list-item-title class="font-weight-bold">{{ "horario" | trans }}</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action>{{ worker.horario ? worker.horario.full_name : "--" }}</v-list-item-action>
							</v-list-item> -->
						</v-list>
					</v-card>

					<!-- Files -->
					<v-card class="my-3 mx-2" max-width="1000px" min-height="350px">
						<v-subheader>
							<v-icon left>mdi-attachment</v-icon>
							{{ "files" | trans }}
							<v-spacer></v-spacer>
							<v-btn depressed v-can="'Editar Trabajador'" @click="uploadFile" fab color="primary" bottom top small>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-subheader>
						<v-divider></v-divider>
						<v-list dense>
							<template v-for="(file, index) in worker.archivos">
								<v-list-item :key="index" :href="`${api.url}api/archivos/${file.id}/download`" target="_blank">
									<v-list-item-avatar :color="colorByType(file.tipo)" class="white--text text-uppercase">
										<v-img
											:src="`${api.url}api/archivos/${file.id}/download`"
											v-if="file.tipo == 'jpg' || file.tipo == 'png'"
										></v-img>
										<small v-else>{{ file.tipo }}</small>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>{{ file.nombre }}</v-list-item-title>
										<v-list-item-subtitle>
											{{ file.descripcion }}
											<span v-if="file.paginas">{{ "pages" | trans }}: {{ file.paginas }}</span>
										</v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action v-can="'Editar Trabajador'">
										<v-btn outlined x-small color="error" @click.prevent="deleteFile(file)">
											<v-icon left>delete</v-icon>
											{{ "crud.delete" | trans }}
										</v-btn>
									</v-list-item-action>
								</v-list-item>
								<v-divider :key="index + 'd'"></v-divider>
							</template>
						</v-list>
					</v-card>
				</v-flex>
			</v-layout>
		</template>
	</v-container>
</template>
<script>
import NewtonMap from "@/components/main/NewtonMap";
import { MglMarker } from "vue-mapbox";
import IMEX from "@/services/IMEX";
export default {
	components: { MglMarker, NewtonMap },
	beforeMount() {
		this.api.can("Ver Trabajadores").then((resp) => {
			if (!resp) {
				return this.$router.replace("/");
			}
		});
	},
	async mounted() {
		await this.getData();
		this.breads = [
			{
				text: this.$options.filters.trans("workers"),
				disabled: false,
				href: "#/workers",
			},
			{
				text: this.worker.full_name,
				disabled: true,
				href: "#",
			},
		];
	},
	methods: {
		async getData() {
			this.loading = true;
			await this.api.ready;
			try {
				this.worker = (
					await this.api.get(`workers/${this.workerId}?include=horario,cliente,entidad,puesto,archivos`)
				).data;
				if (this.worker.location) {
					this.coords = [
						this.worker.location.longitude || this.worker.location.lng,
						this.worker.location.latitude || this.worker.location.lat,
					];
				} else {
					this.coords = [0, 0];
					this.zoom = 2;
				}
			} catch (error) {
				this.api.handleError(error);
			}
			this.loading = false;
		},
		async uploadFile() {
			var file = await IMEX.import();
			await this.api.post(`archivos`, {
				file: file.file,
				resource_id: this.worker.id,
				resource: "Worker",
			});
			this.getData();
		},
		async deleteFile(file) {
			if (confirm(this.$options.filters.trans("crud.delete_confirm"))) {
				await this.api.delete(`archivos/${file.id}`);
				this.getData();
			}
		},
		colorByType(tipo) {
			if (tipo == "xlsx" || tipo == "csv" || tipo == "xls") return "green lighten-1";
			if (tipo == "pdf") return "red darken-2";

			return "primary";
		},
	},
	data() {
		return {
			breads: [],
			worker: {},
			loading: false,
			zoom: 5,
			coords: [-74.16609564000794, 4.5443162057551945],
		};
	},
	props: {
		workerId: {
			type: String,
		},
	},
};
</script>
